import * as React from 'react';
import type { HeadFC } from 'gatsby';
import NavigationSpacer from '../components/NavigationSpacer';

const IndexPage = () => {
  return (
    <main
      style={{
        background: 'url("/pexels-wendy-wei-1306791.jpg")',
        backgroundSize: 'cover',
        minHeight: 1000,
        backgroundPositionX: 'center'
      }}
    >
      <NavigationSpacer />
      <div style={{ marginTop: 200, textAlign: 'center' }}>
        <h1 style={{fontSize: '4em', fontFamily: 'Piedra', fontWeight: 100 }}>Coming soon 🥳</h1>
      </div>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>We Got Maggots</title>;
